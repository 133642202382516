<template>
  <!--/inner-page-->
  <div class="inner-banner inner-banner-sobre py-5">
    <section class="w3l-breadcrumb text-left py-sm-5">
      <div class="container">
        <div class="w3breadcrumb-gids">
          <div class="w3breadcrumb-left text-left">
            <h2 class="inner-w3-title"><br /></h2>
          </div>
          <div class="w3breadcrumb-right">
            <ul class="breadcrumbs-custom-path">
              <li><a href="/"></a></li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
  <!--//inner-page-->
  <div style="margin: 8px auto; display: block; text-align: center">
    <!---728x90--->
  </div>

  <!-- -->
  <!--/bottom-3-grids-->
  <div class="w3l-3-grids py-5" id="about-1">
    <div class="container py-md-5 py-2 pb-0">
      <!--/row-1-->
      <div class="w3abin-top text-center" style="display: none">
        <div class="title-content">
          <!-- <h6 class="title-subw3hny mb-1">Our Info</h6> -->
          <h3 class="title-w3l">Sobre</h3>
        </div>
        <p class="mt-3">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
          quis rhoncus dui. In sed risus tincidunt, pharetra enim sed, cursus
          mauris. Cras tempor risus at sapien porta, ac tristique eros
          fringilla. Praesent bibendum sed neque ac cursus. Pellentesque
          pulvinar dictum mi, ut varius quam ultricies vel. Proin eleifend
          commodo feugiat. Fusce placerat facilisis pulvinar. Integer ac dui eu
          elit aliquet facilisis id nec ex. In porta, leo ut elementum commodo,
          ex ligula pharetra nibh, nec condimentum odio sapien sed lacus. Etiam
          commodo ex a ex tincidunt, sit amet efficitur lorem interdum. Fusce id
          mi eleifend, porttitor mi nec, ultrices turpis. Sed nulla enim,
          eleifend in tempus vel, hendrerit in ante. Aliquam et mattis metus.
          <br />
          Nulla nec rhoncus nulla. Nullam ultrices volutpat erat, eget porttitor
          ligula efficitur in. Nullam at urna a sapien aliquet convallis vitae
          eget elit. Vestibulum pulvinar placerat pharetra. Sed suscipit diam
          velit, a fringilla justo gravida in. Aliquam dignissim ut enim at
          luctus. Phasellus suscipit, nulla in sodales tempus, lectus lectus
          dapibus enim, et tincidunt tellus neque eu quam. Morbi ultricies
          bibendum mauris eu congue.
        </p>
        <!-- <a href="about.html#" class="btn btn-style btn-primary mt-sm-5 mt-4">Read More<i class="fas fa-arrow-right ms-lg-3 ms-2"></i></a> -->
      </div>
      <!--//row-1-->

      <!--/row-1-->
      <div class="w3abin-top">
        <div class="title-content">
          <h3 class="title-w3l">A ECOLATAS</h3>
        </div>
        <div class="row">
          <div class="col-8">
            <p class="mt-3">
              <br /><br />
              Somos uma empresa especializada no comércio de painéis laterais de
              veículos - peças genuínas inteiras e parciais.<br />
              Possuímos um estoque com mais de 6 mil peças de diversas marcas e
              modelos.<br />
              Contamos também com parcerias em outros estados do Brasil,
              facilitando e agilizando a entrega.<br />
              Trabalhamos com peças inteiras e no tamanho da sua necessidade,
              diminuindo o custo para nossos clientes.
            </p>
          </div>
          <div class="col-4">
            <img
              class="pic-1 img-fluid radius-image"
              src="/assets/images/sobre-1.png"
            />
          </div>
        </div>
        <!-- <a href="about.html#" class="btn btn-style btn-primary mt-sm-5 mt-4">Read More<i class="fas fa-arrow-right ms-lg-3 ms-2"></i></a> -->
      </div>
      <!--//row-1-->

      <!--/row-2-->
      <div class="w3abin-top">
        <div class="row">
          <div class="col-12">
            <p class="mt-3">
              A utilização do retalho é feita por meio de técnicas aplicadas por
              um profissional funileiro que consiste em consertar peças de
              lataria de um veículo, a fim de manter o máximo possível de sua
              originalidade após sofrer algum tipo de avaria irrecuperável, ou
              seja, peças que sofreram algum problema são retiradas e
              consertadas pelo funileiro.
              <br />Em alguns casos não há possibilidade de reparo, então a
              oficina de funilaria também é a responsável pela troca desta peça
              ou parte dela com o retalho automotivo. <br />Listamos algumas das
              características que as peças originais possuem: <br />• Pontos de
              fixação sem necessidade de ajuste. <br />• Melhor acabamento das
              peças. <br />• Alta resistência à corrosão. <br />• Controle de
              100% das peças com relação à rugosidade.
            </p>
          </div>
        </div>
        <!-- <a href="about.html#" class="btn btn-style btn-primary mt-sm-5 mt-4">Read More<i class="fas fa-arrow-right ms-lg-3 ms-2"></i></a> -->
      </div>
      <!--//row-2-->

      <!--/row-2-->
      <div class="w3abin-top">
        <div class="row">
          <div class="col-12">
            <img
              class="pic-1 img-fluid radius-image"
              src="/assets/images/sobre-2.png"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <p class="mt-3">
              <br /><br />
              Nosso Produto é original e com procedência, proporcionam melhor
              acabamento e garantem uma perfeita montagem e alinhamento da peça
              na aplicação ao veículo.<br />
              Nosso Atendimento é de forma Presencial e Online (WhatsApp)
              <br />
              Endereço Rua Quarai, 980 - Imigrante - Campo Bom<br />
              WhatsApp (51) 99106 6131<br />
              Enviamos para todo Brasil!<br />
            </p>
          </div>
        </div>
        <!-- <a href="about.html#" class="btn btn-style btn-primary mt-sm-5 mt-4">Read More<i class="fas fa-arrow-right ms-lg-3 ms-2"></i></a> -->
      </div>
      <!--//row-2-->
    </div>
  </div>
  <!--//bottom-3-grids-->
  <!-- -->
</template>

<script>
// import LayoutHeader from "@/views/layout/layout-site-header";
// import LayoutFooter from "@/views/layout/layout-site-footer";

import { onMounted } from "vue";
// import useSite from '@/composables/Site';

export default {
  components: {
    // LayoutHeader,
    // LayoutFooter,
  },

  setup() {
    // const { meta, paramsSearch, marcas, getMarcas } = useSite();

    onMounted(function () {
      // getMarcas();
    });

    return {
      // paramsSearch,
      // meta,
      // marcas,
      // publicUrl: `${process.env.VUE_APP_URL_PUBLIC+'/produto/'}`
    };
  },
};
</script>

<style></style>
